<template>
  <HXContentCard title="Lista de Areas">
    <div class="text-right px-4 mt-4">
      <div class="d-flex justify-content-end">
        <vs-button
          icon
          animation-type="vertical"
          class="mx-1 text-right"
          @click="$router.push('Areas_agregar')"
        >
          <i class="fas fa-plus-circle pr-1"></i>
          Agregar Area
          <template #animate>
            <i class="fas fa-plus-circle"></i>
          </template>
        </vs-button>
      </div>
    </div>
    <div class="table-responsive px-4 mt-3">
      <table
        id="datatable_areas"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre Área</th>
            <th>Departamento</th>
            <th>Empresa</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listaItems" :key="item.id">
            <td>
              <strong>
                {{ index + 1 }}
              </strong>
            </td>
            <td>{{ item.descripcion }}</td>
            <td>{{ item.departamento.descripcion }}</td>
            <td>{{ item.empresa.nombre }}</td>
            <td style="width: 150px !important">
              <ButtonGroup :Id="item.id" :data="dataIcons"></ButtonGroup>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </HXContentCard>
</template>
<script>
import { core } from "../../../config/pluginInit";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    ButtonGroup,
  },
  name: "DataTable",
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      usuario: {},
      dataIcons: [
        {
          type: "success",
          tooltip: "Editar area",
          title: "",
          value: () => 10,
          iconName: "pen",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.$router.push("Areas_editar/" + Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },

        {
          type: "danger",
          tooltip: "Eliminar registro",
          title: "",
          value: () => 0,
          iconName: "trash",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.deleteRow(Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      window.$("#datatable_areas").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Area/Delete/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      //console.log(this.persona.empresa.id);
      try {
        console.log("departamentoss.....................");
        let res = await this.$store.dispatch("hl_get", {
          path: "Area/ListArea/" + this.usuario.empresa.id,
        });
        console.log("post departamento... ", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
